import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';

const DancersFeeRow = ({ row, eventEntries, currentEvent }) => {
  const navigate = useNavigate();
  const { event_name } = useParams();

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  //==========================================================================to get all entrytypes & pricing

  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    'competitions',
    compId
  );

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);
  const [taxRate, setTaxRate] = useState(0.0);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, compStatus, currentEvent]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  const dancerEntries = eventEntries?.filter((entry) =>
    entry?.dancers_selected.some(
      (dancerSelected) => dancerSelected?.dancer_uid === row?.dancer_uid
    )
  );

  // ====================================================================================Calculate the totalAmount for the specific dancer
  const newTotalAmountForDancer = allEntryTypes
    ?.reduce((total, type) => {
      let rowPricingOption;
      if (type?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(type?.entryTypeName)
        );
      } else if (type?.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(type?.specialEntryTypeName)
        );
      }

      // to calculate special entries and normal entries
      function rowEntries1(arr) {
        const result = arr?.filter(
          (itm) =>
            itm?.entryType === type?.entryTypeName &&
            !itm?.category_name?.includes('impulse') &&
            !itm?.category_name?.includes('remix') &&
            !itm?.category_name?.includes('unity') &&
            !itm?.category_name?.includes('overtime')
        );
        return result;
      }

      function rowEntriesSpecial(arr) {
        const result = arr?.filter((x) =>
          x?.category_name
            ?.toLowerCase()
            ?.includes(type?.specialEntryTypeName?.toLowerCase())
        );
        return result;
      }

      const rowEntriesForDancer =
        type?.specialEntryTypeName?.includes('impulse') ||
        type?.specialEntryTypeName?.includes('remix') ||
        type?.specialEntryTypeName?.includes('unity') ||
        type?.specialEntryTypeName?.includes('overtime')
          ? rowEntriesSpecial(dancerEntries)
          : rowEntries1(dancerEntries);

      const flatAmount =
        rowEntriesForDancer?.length > 0
          ? rowPricingOption?.[type?.entryTypeName] ||
            rowPricingOption?.[type?.specialEntryTypeName] *
              rowEntriesForDancer?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === 'flat'
          ? Number(flatAmount)
          : rowEntriesForDancer?.length *
            Number(
              rowPricingOption?.[type?.entryTypeName] ||
                rowPricingOption?.[type?.specialEntryTypeName]
            );

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTaxForDancer =
    Number(newTotalAmountForDancer) +
    Number(calcTotalAfterTax(newTotalAmountForDancer, taxRate));

  return (
    <>
      <tr
        className="border-y border-white/10 hover:bg-white/10 relative whitespace-nowrap transition-all duration-500"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <td className="flex items-center mt-2">
          <div className="w-6 h-6 rounded-full border-4 border-white/60"></div>
          {capitalizeFirstLetter(row?.first_name)}{' '}
          {capitalizeFirstLetter(row?.last_name)}
        </td>
        <td> {dancerEntries?.length}</td>
        <td>{totalAfterTaxForDancer}</td>
        <td>
          <button
            className="w-[80px] text-[.85rem] py-0 px-5 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            onClick={() => {
              navigate(
                `/studio/event/${event_name.replace(/ /g, '-')}/dancers-fees/${
                  row?.dancer_uid
                }`
              );
            }}
          >
            View
          </button>
        </td>
      </tr>
    </>
  );
};

export default DancersFeeRow;
