import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  addDivision,
  removeDivision,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const AgeDivisionsSchema = Yup.object().shape({
  divisionName: Yup.string()
    .required('Division name is required')
    .matches(/^[^0-9]*$/, 'Division name should not contain numbers'),
  minAge: Yup.number()
    .required('Min age is required')
    .min(1, 'Min age must be 1 or more')
    .max(Yup.ref('maxAge'), 'Min age must be less than or equal to Max age'),
  maxAge: Yup.number()
    .required('Max age is required')
    .min(Yup.ref('minAge'), 'Max age must be greater than or equal to Min age')
    .notOneOf([Yup.ref('minAge')], 'Max age cannot be equal to Min age'),
});

const AgeDivision = () => {
  const dispatch = useDispatch();
  const { eligibilityDivisions } = useSelector(
    (state) => state.persistedReducer
  );

  const onSubmit = (values, { resetForm }) => {
    if (formik.isValid) {
      const division = {
        ...values,
        divisionName: values.divisionName.toLowerCase(),
      };
      dispatch(addDivision(division));
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      divisionName: '',
      minAge: '',
      maxAge: '',
    },
    validationSchema: AgeDivisionsSchema,
    onSubmit,
  });

  // check if the divisionName is already added
  const index = eligibilityDivisions.findIndex(
    (division) => division.divisionName === formik.values.divisionName
  );

  // if the divisionName is already added don't allow to add it again and return error
  if (index !== -1) {
    formik.errors.divisionName = 'Division Name already exists';
  }

  const handleRemove = (index) => {
    dispatch(removeDivision(index));
  };

  return (
    <div className="w-full form-control">
      <label className="label">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What age divisions do you have at your competition?
        </span>
      </label>

      {/* {AGE  DIVISION } */}
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col gap-2">
              <input
                name="divisionName"
                type="text"
                placeholder="Division Name"
                onChange={formik.handleChange}
                value={formik.values.divisionName}
                onBlur={formik.handleBlur}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.divisionName &&
                  formik.touched.divisionName &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.divisionName && formik.touched.divisionName && (
                <span className="text-xs text-red-500">
                  {formik.errors.divisionName}
                </span>
              )}
            </div>
            <div className="w-full md:w-[60%] flex flex-col gap-2">
              <input
                name="minAge"
                type="number"
                placeholder="Min Age"
                onChange={formik.handleChange}
                value={formik.values.minAge}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[80%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.minAge &&
                  formik.touched.minAge &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.minAge && formik.touched.minAge && (
                <span className="text-xs text-red-500">
                  {formik.errors.minAge}
                </span>
              )}
            </div>
            <div className="w-full md:w-[60%] flex flex-col gap-2">
              <input
                name="maxAge"
                type="number"
                placeholder="Max Age"
                onChange={formik.handleChange}
                value={formik.values.maxAge}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[80%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.maxAge &&
                  formik.touched.maxAge &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.maxAge && formik.touched.maxAge && (
                <span className="text-xs text-red-500">
                  {formik.errors.maxAge}
                </span>
              )}
            </div>

            <button
              type="submit"
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-2 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {eligibilityDivisions.map((division, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mr-[10px] mb-[8px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${division.divisionName} ${division.minAge} - ${division.maxAge}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              data-testid={`remove-${index}`} // Unique identifier
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgeDivision;
