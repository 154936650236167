import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import {
  registerOrganizer,
  login,
  logout,
  resetPassword,
} from '../../services/authenticationService';
import { resetState } from '../organizerRegistration/organizerSlices';

const initialState = {
  user: null,
  status: 'idle',
  error: null,
};

// Create async thunk for login action
export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials, thunkAPI) => {
    localStorage.removeItem('event_name');
    localStorage.removeItem('persist:root');

    try {
      const response = await login(credentials.email, credentials.password);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (thunkAPI, { dispatch }) => {
    // const navigate = useNavigate();
    try {
      const response = await logout();
      dispatch(resetState());
      // Dispatch the PURGE action to clear the persisted state
      dispatch({ type: PURGE, key: 'persist:root', result: () => null });
      localStorage.removeItem('event_name');
      localStorage.removeItem('alertsCleared');
      localStorage.removeItem('alerts');
      localStorage.removeItem('suggestionCleared');
      localStorage.removeItem('suggestion');
      localStorage.removeItem('checkboxData');
      localStorage.removeItem('selectedEntry');
      localStorage.removeItem('affectedItems');
      window.location.reload();
      window.open('/', '_self');

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPsw = createAsyncThunk(
  'auth/resetPsw',
  async (email, thunkAPI) => {
    try {
      const response = await resetPassword(email);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, status: 'idle', error: null },
  reducers: {
    setFormCompleted: (state, action) => {
      return {
        ...state,
        user: { ...state.user, formCompleted: action.payload },
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(registerOrganizer.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(registerOrganizer.rejected, (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    });
    // Handle login action
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    });

    // Handle resetPsw action
    builder.addCase(resetPsw.pending, (state, action) => {
      state.status = 'pending';
    });
    builder.addCase(resetPsw.fulfilled, (state, action) => {
      state.status = 'succeeded';
    });
    builder.addCase(resetPsw.rejected, (state, action) => {
      state.error = action.error.message;
      state.status = 'failed';
    });
  },
});

export const { setFormCompleted } = authSlice.actions;
export default authSlice.reducer;
