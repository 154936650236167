import { useState } from "react";
import calculateFractionalIndex from "utils/calculateFractionalIndex";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const MiniCategory = ({
  category,
  sortedCategories,
  index,
  setSortedCategories,
  affectedItems2,
  setaffectedItems2,
  setselectedCategory,
  selectedCategory,
}) => {
  const categoriesBeforeCurrent = sortedCategories?.slice(0, index) || [];

  const sessionsBeforeCurrent = categoriesBeforeCurrent?.filter(
    (item) => item?.break_type === "session"
  );

  const [showMoveCategory, setshowMoveCategory] = useState(false);
  const [showMoveCategoryBefore, setshowMoveCategoryBefore] = useState(false);

  function handleSelect(item) {
    if (selectedCategory?.id !== item?.id) {
      setselectedCategory(null);
      setTimeout(() => {
        setselectedCategory(item);
      }, 200);
    } else {
      setselectedCategory((prev) => (prev ? null : item));
    }
  }

  // const categoryWithouCurrentRaw = sortedCategories?.filter(
  //   (itm) => itm?.id !== selectedCategory?.id
  // );

  const [searchTerm, setSearchTerm] = useState("");

  const filteredCategories = sortedCategories?.filter((x) =>
    x?.category_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  

  const categoryWithouCurrent = searchTerm
    ? filteredCategories
    : sortedCategories;

  function toggleMoveCate() {
    setshowMoveCategory((prev) => !prev);
    setSearchTerm("");
  }

  function toggleMoveCateBefore() {
    setshowMoveCategoryBefore((prev) => !prev);
    setSearchTerm("");
  }

  //===========================================================to rearrange categories
  const rearrangeCategories = async (id1, id2) => {
    const sourceIndex = sortedCategories?.findIndex(
      (category) => category?.id === id1
    );

    const destinationIndex = sortedCategories?.findIndex(
      (category) => category?.id === id2
    );

    const updatedCategory = [...sortedCategories];
    const draggedRow = updatedCategory[sourceIndex];

    // Remove the dragged category from its original position
    updatedCategory?.splice(sourceIndex, 1);

    // Insert the dragged category at the destination index
    updatedCategory?.splice(destinationIndex, 0, draggedRow);

    // Calculate the new sort_id using fractional indexing
    const previousSortId =
      destinationIndex === 0
        ? 0
        : updatedCategory[destinationIndex - 1].sort_id;
    const nextSortId =
      destinationIndex === updatedCategory.length - 1
        ? updatedCategory[destinationIndex - 1].sort_id + 3
        : updatedCategory[destinationIndex + 1].sort_id;

    const newSortId = calculateFractionalIndex(previousSortId, nextSortId);

    // console.log("previousSortId", previousSortId);
    // console.log("newSortId", newSortId);

    draggedRow.sort_id = newSortId;

    setSortedCategories(updatedCategory);

    // console.log("updatedCategory", updatedCategory);

    //add dragged row to the affected items
    let allAffectedItems2 = [...affectedItems2];
    const index = allAffectedItems2.findIndex(
      (item) => item.id === draggedRow.id
    );

    if (index !== -1) {
      // If the draggedRow exists, replace it in the affectedItems2 array
      allAffectedItems2[index] = draggedRow;
    } else {
      // If the draggedRow doesn't exist, add it to the allAffectedItems2 array
      allAffectedItems2.push(draggedRow);
    }

    localStorage.setItem("affectedItems2", JSON.stringify(allAffectedItems2));
    setaffectedItems2(allAffectedItems2);
    setSearchTerm("");
  };
  return (
    <div
      onClick={(e) => {
        e?.stopPropagation();
        handleSelect(category);
      }}
      id={category?.id}
      className={`relative ${
        selectedCategory?.id === category?.id ? "mb-[80px]" : ""
      }`}
    >
      {category?.is_scratched && (
        <div className="w-full h-full bg-stone-700/40 absolute top-0 left-0"></div>
      )}
      <div
        className={`w-full min-w-[900px] p-3 lg:text-center font-bold cursor-pointer relative border-y border-gray-500/10 flex items-center ${
          category?.type === "buffer"
            ? "bg-[#4f2d54]"
            : category?.break_type === "session"
            ? "bg-[#42125f]"
            : category?.break_type?.includes("overall") ||
              category?.break_type?.includes("award")
            ? "bg-black/20"
            : "bg-[#282929]"
        } ${
          category?.break_type?.includes("overall") ||
          category?.break_type?.includes("award")
            ? "uppercase"
            : ""
        }`}
      >
        {category?.is_scratched && (
          <img alt="" src="/images/scratchCat.png" className="w-5 h-3" />
        )}
        <h2
          id={
            category?.break_type === "session"
              ? sessionsBeforeCurrent?.length + 1
              : category?.id
          }
          className={`${
            category?.is_scratched ? "text-white/50" : "text-white"
          } ${
            category?.type !== "category" && "uppercase"
          } flex gap-1 items-center lg:justify-center lg:mx-auto`}
        >
          {capitalizeFirstLetter(category?.category_name?.replace(/_/g, " "))}
          {category?.split && !category?.category_name?.includes("(") && (
            <span className="">(A)</span>
          )}

          {/*========================================================================= to dynamically render overalls or grand award tags */}
          {category?.break_type?.includes("overalls_") && (
            <span className="">
              {category?.break_type?.split("_")[1] === "overall"
                ? "OVERALLS"
                : category?.break_type?.split("_")[1] === "grand"
                ? "GRAND OVERALLS"
                : "CUSTOM REPORT"}
            </span>
          )}
          {/* to dynamically render sessions count */}
          {category?.break_type === "session" && (
            <span className="">
              {" "}
              {sessionsBeforeCurrent?.length === 0
                ? 1
                : sessionsBeforeCurrent?.length + 1}
            </span>
          )}
        </h2>
        {!category?.is_scratched && (
          <div
            // onClick={() => {
            //   console.log("clear", loadingEntry);
            //   setLoadingEntry(false);
            // }}
            className="w-full h-full absolute top-0 left-0"
          ></div>
        )}
      </div>
      {category?.type === "category" && (
        <div className="h-[20px] w-full border-b-2 border-white/20 border-x-2 mb-1 rounded-b-lg flex justify-center items-center">
          ...
        </div>
      )}

      {/* the dropdown on click */}
      {selectedCategory?.id === category?.id && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`w-[270px] bg-[#282929] p-3 rounded-lg absolute top-0 left-0 font-normal shadow-lg z-[1] border border-white/30 `}
        >
          {showMoveCategory && (
            <ul className="h-[170px] flex flex-col overflow-y-auto overflow-x-hidden">
              <li className="w-full flex gap-2 items-center cursor-pointer">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMoveCate();
                  }}
                  className="w-fit rounded-md bg-white/10 hover:bg-white/20 py-1 pl-2 pr-5 mb-2 text-white/60 text-[.75rem] cursor-pointer flex items-center"
                >
                  <img alt="" className="w-5 h-5" src="/images/leftArrow.png" />
                  Cancel
                </button>{" "}
                <p className="font-bold text-white/50">To after:</p>
              </li>
              <div className="my-2">
                <input
                  type="text"
                  id="search"
                  className="w-[80%] border border-white/20 rounded-md p-1 outline-none text-[.85rem] placeholder:text-[.8rem] bg-[#282929]"
                  placeholder="Search category"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>

              {categoryWithouCurrent?.map((itm, idx, arr) => {
                const sessionsBeforeCurrent = arr
                  ?.slice(0, idx)
                  ?.filter((x) => x?.break_type === "session");
                return (
                  <li
                    key={idx}
                    onClick={(e) => {
                      e.stopPropagation();
                      rearrangeCategories(selectedCategory?.id, itm?.id);
                    }}
                    className={`px-1 py-[3px] hover:bg-[#94a4fd]/30 text-[.7em] font-medium cursor-pointer border-b border-white/20`}
                  >
                    {capitalizeFirstLetter(itm?.category_name)}{" "}
                    {itm?.break_type === "session" &&
                      sessionsBeforeCurrent?.length + 1}
                    {itm?.split && !itm?.category_name?.includes("(") && (
                      <span className="">(A)</span>
                    )}
                  </li>
                );
              })}
              {categoryWithouCurrent?.length === 0 && (
                <div className="w-full h-full border border-white/10 rounded-lg flex justify-center items-center text-white/50 p-1">
                  No available categories for repositioning after the current
                  one. Do not include spaces while searching for sessions.
                </div>
              )}
            </ul>
          )}

          {showMoveCategoryBefore && (
            <ul className="h-[170px] flex flex-col overflow-y-auto overflow-x-hidden">
              <li className="w-full flex gap-2 items-center cursor-pointer">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMoveCateBefore();
                  }}
                  className="w-fit rounded-md bg-white/10 hover:bg-white/20 py-1 pl-2 pr-5 mb-2 text-white/60 text-[.75rem] cursor-pointer flex items-center"
                >
                  <img alt="" className="w-5 h-5" src="/images/leftArrow.png" />
                  Cancel
                </button>{" "}
                <p className="font-bold text-white/50">To before:</p>
              </li>

              <div className="my-2">
                <input
                  type="text"
                  id="search"
                  className="w-[80%] border border-white/20 rounded-md p-1 outline-none text-[.85rem] placeholder:text-[.8rem] bg-[#282929]"
                  placeholder="Search category"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>

              {categoryWithouCurrent?.map((itm, idx, arr) => {
                const sessionsBeforeCurrent = arr
                  ?.slice(0, idx)
                  ?.filter((x) => x?.break_type === "session");
                return (
                  <li
                    key={idx}
                    onClick={(e) => {
                      e.stopPropagation();
                      rearrangeCategories(selectedCategory?.id, itm?.id);
                    }}
                    className={`px-1 py-[3px] hover:bg-[#94a4fd]/30 text-[.7em] font-medium cursor-pointer border-b border-white/20`}
                  >
                    {capitalizeFirstLetter(itm?.category_name)}{" "}
                    {itm?.break_type === "session" &&
                      sessionsBeforeCurrent?.length + 1}
                    {itm?.split && !itm?.category_name?.includes("(") && (
                      <span className="">(A)</span>
                    )}
                  </li>
                );
              })}
              {categoryWithouCurrent?.length === 0 && (
                <div className="w-full h-full border border-white/10 rounded-lg flex justify-center items-center text-white/50 p-1">
                  No available categories for repositioning after the current
                  one. Do not include spaces while searching for sessions.
                </div>
              )}
            </ul>
          )}

          {!showMoveCategory && !showMoveCategoryBefore && (
            <ul className="flex flex-col w-full my-2">
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMoveCateBefore();
                }}
                className="w-full rounded-md bg-white/10 hover:bg-white/20 py-1 px-2 text-white/50 text-[.85rem] cursor-pointer flex gap-2 items-center justify-between"
              >
                <p>
                  Move {category?.type === "category" && "category"} to
                  BEFORE...
                </p>
                <img
                  alt=""
                  src="/images/icons8-up-white.png"
                  className="w-4 h-auto"
                />
              </li>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMoveCate();
                }}
                className="w-full rounded-md bg-white/10 hover:bg-white/20 py-1 px-2 text-white/50 text-[.85rem] cursor-pointer flex gap-2 items-center justify-between mt-2"
              >
                <p>
                  Move {category?.type === "category" && "category"} to AFTER...
                </p>
                <img
                  alt=""
                  src="/images/icons8-down-white.png"
                  className="w-4 h-auto"
                />
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default MiniCategory;
