const SelectCompButton = ({ item, handleSelectComp }) => {
  return (
    <p
      onClick={() => {
        // setPopup(item);
        handleSelectComp(item?.competitionName);
      }}
      className={`text-[.85rem] px-3 py-1 mr-2 cursor-pointer text-[#94a4fd] hover:underline transition-all duration-300`}
    >
      Select Competition
    </p>
  );
};

export default SelectCompButton;
