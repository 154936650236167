// import { useSelector } from "react-redux";

const ViewCompButton = ({ item, handleViewComp }) => {
  return (
    <p
      onClick={handleViewComp}
      className={`text-[.85rem] px-2 py-1 mr-1 cursor-pointer text-[#94a4fd] hover:underline transition-all duration-300`}
    >
      View Competition
    </p>
  );
};

export default ViewCompButton;
