import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from 'redux/auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import ImgWrap from '../UI/ImgWrapper';
import BackIcon from '../../assets/arrow_back.png';

const EmceeLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  function toggleShow() {
    setShow((prev) => !prev);
  }

  const [formErr, setformErr] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  // console.log(formData);

  function handleInputChange(event) {
    setformErr('');
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  }

  const [loading, setLoading] = useState(false);

  async function handleLogin(e) {
    e.preventDefault();

    if (formData?.email && formData?.password) {
      setLoading(true);

      try {
        const response = await dispatch(
          loginUser({ email: formData?.email, password: formData?.password })
        );

        const { role, formCompleted } = unwrapResult(response);
        if (role === 'emcee') {
          if (formCompleted) {
            navigate('/emcee-competitions');
            console.log(formCompleted);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setformErr('Invalid login credentials');
      } finally {
        setLoading(false);
      }
    } else {
      setformErr('Please fill all fields');
    }
  }

  const [passwordShown, setPasswordShown] = useState(false);

  const passwordVisibility = () => {
    setPasswordShown((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-row flex-wrap h-[100vh]">
        {/* The left content holds logo and background image */}
        <div className="lg:w-[40%] lg:flex hidden">
          <ImgWrap className="bg-[url('../public/emcee.png')]" />
        </div>

        {/* the container wrapper */}
        <div className="lg:w-[60%] w-full">
          <main className="w-full h-screen overflow-y-auto bg-[#1C1C1C] p-5 flex justify-center items-center relative text-white/60">
            <div
              className="absolute top-3 md:top-5 md:left-5 left-1"
              onClick={() => navigate('/')}
            >
              <div className="flex flex-row items-center cursor-pointer">
                <img
                  src={BackIcon}
                  className="w-[9.73px] h-[16.5px] mr-[8px]"
                  alt="backIcon"
                />
                <span className="text-base text-[#8692A6]">Back</span>
              </div>
            </div>
            <p className="absolute top-3 md:top-5 md:right-5 right-1">
              Don't have an account?{' '}
              <Link to="/emcee-registration">
                <span className="text-[#1565D8] font-bold hover:underline">
                  Register
                </span>
              </Link>
            </p>
            <div className="w-full md:w-[550px] min-h-[500px] flex flex-col">
              <h1 className="text-white text-[1.5rem] md:text-[2rem] font-medium">
                Emcee Login
              </h1>
              <form className="mt-8">
                <div className="mb-4">
                  <label htmlFor="email" className="text-white/50">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="E.g johndoe@email.com"
                    onChange={handleInputChange}
                    className="w-full bg-white p-4 rounded-md outline-blue-500 text-black placeholder:text-black/50 mt-2"
                  />
                </div>
                <div className="mb-4 relative">
                  <label htmlFor="password" className="text-white/50">
                    Password
                  </label>
                  <input
                    type={show ? 'text' : 'password'}
                    id="password"
                    onChange={handleInputChange}
                    className="w-full bg-white p-4 rounded-md outline-blue-500 text-black placeholder:text-black/50 mt-2"
                  />
                  <p
                    onClick={toggleShow}
                    className="text-blue-500 absolute bottom-4 right-3 cursor-pointer"
                  >
                    {passwordShown ? (
                      <span
                        className="material-symbols-outlined flex cursor-pointer"
                        onClick={passwordVisibility}
                      >
                        <i className="fa-regular fa-eye"></i>
                      </span>
                    ) : (
                      <span
                        className="material-symbols-outlined flex cursor-pointer"
                        onClick={passwordVisibility}
                      >
                        <i className="fa-regular fa-eye-slash"></i>
                      </span>
                    )}
                  </p>
                </div>

                {formErr && <p className="text-red-500">{formErr}</p>}

                <button
                  onClick={handleLogin}
                  className="p-4 w-[70%] min-w-fit bg-blue-600 text-white rounded-md mt-6 flex justify-between gap-2 items-center"
                >
                  <span className="w-full">
                    {loading ? 'Processing...' : 'Login'}
                  </span>
                  {loading && <ButtonLoadingSpinner />}
                </button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default EmceeLogin;
