/* eslint-disable react-hooks/exhaustive-deps */
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "services/firebase";

/**
 * Custom React hook to fetch paginated schedule documents from Firestore.
 * Developed by Khalid at Atop Web Technologies.
 * 
 * This hook is responsible for querying schedule documents based on various filters,
 * pagination controls, and also ensures the integrity of category names by updating
 * any falsely labeled category name to 'unknown category name'.
 * 
 * @param {string} collectionName Name of the Firestore collection.
 * @param {string} compId Company ID for query reference.
 * @param {string} subCollection Name of the sub-collection.
 * @param {string} filterField Field name to apply the filter on.
 * @param {string} filterValue Value to filter the documents.
 * @param {number} pageSize Number of documents per page.
 * @param {DocumentSnapshot} startAfterDoc Document to start after for pagination.
 * @param {DocumentSnapshot} startBeforeDoc Document to start before for pagination.
 * @param {Function} setcurrentPage Function to set the current page.
 * @param {Function} setTotal Function to set the total number of documents.
 * @param {number} currentPage Current page number.
 * @param {...Function} updateHooks Various hooks for updating UI based on CRUD operations.
 * @returns {Object} Object containing event categories and loading state.
 */
export const useFetchPaginatedScheduleDocs = (
  collectionName,
  compId,
  subCollection,
  filterField,
  filterValue,
  pageSize,
  startAfterDoc,
  startBeforeDoc,
  setcurrentPage,
  setTotal,
  currentPage,
  ...updateHooks
) => {
  const [eventCategories, setEventCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      if (filterValue) {
        // Reference to the collection and queries setup
        let ref = collection(db, collectionName, compId, subCollection);
        let dataQuery = query(
          ref,
          where(filterField, "==", filterValue || ""),
          orderBy("sort_id"),
          limit(pageSize)
        );
        const snapshotSize = await getDocs(query(ref, where(filterField, "==", filterValue || "")));
        setTotal(snapshotSize?.size);

        // Adjusting query for pagination
        if (startAfterDoc) {
          dataQuery = query(dataQuery, startAfter(startAfterDoc));
        } else if (startBeforeDoc) {
          dataQuery = query(dataQuery, startAt(startBeforeDoc));
        }

        // Fetching and processing documents
        const snapshot = await getDocs(dataQuery);
        const data = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          let docData = docSnapshot.data();
          // Check and update false category names
          if (docData?.category_name === false) {
            const docRef = doc(db, collectionName, compId, subCollection, docSnapshot.id);
            await updateDoc(docRef, { "category_name": "unknown category name" });
            docData.category_name = "unknown category name";
          }
          return docData;
        }));

        setEventCategories(data);
      } else {
        setEventCategories([]);
      }
      setLoading(false);
    }

    fetch();
  }, [filterValue, startAfterDoc, startBeforeDoc, ...updateHooks]);

  return { eventCategories, loading };
};
