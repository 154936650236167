import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyArFhwNlhDFOwj3Juy-cj8QpinpC0QbWfs",
  authDomain: "dance-competition-management.firebaseapp.com",
  projectId: "dance-competition-management",
  storageBucket: "dance-competition-management.appspot.com",
  messagingSenderId: "789118893645",
  appId: "1:789118893645:web:7ec0386330bdf2b689a604",
};

// Initialize Firebase and get a reference to the services
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Conditionally initialize Firebase Analytics (only in supported environments)
let analytics;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics(app);
  }
});

// Export the initialized services
export { app, db, functions, auth, storage, analytics };
