/* eslint-disable no-loop-func */
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, functions } from "./firebase";
import { httpsCallable } from "firebase/functions";
import moment from "moment";
import { updateAllDocs } from "utils/updateAllDocs";
import { createAsyncThunk } from "@reduxjs/toolkit";

const sendAccessGrantedEmail = httpsCallable(
  functions,
  "sendAccessGrantedEmail"
);
const sendSubscriptionEmail = httpsCallable(functions, "sendSubscriptionEmail");
const sendAgeOverrideEmail = httpsCallable(functions, "sendAgeOverrideEmail");
const sendMusicReminder = httpsCallable(functions, "sendMusicReminder");
const fetchScheduleDocs = httpsCallable(functions, "fetchScheduleDocs");

//========================================================to fetch categories and entries
export const fetchScheduleDocsService = async (data) => {
  const { collectionName, compId, subCollection, filterField, filterValue } =
    data;

  try {
    //call cloud function
    const response = await fetchScheduleDocs({
      collectionName,
      compId,
      subCollection,
      filterField,
      filterValue,
    });

    return response;
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};

//========================================================to send event access granted email
export const sendAccessGrantedEmailService = async (data) => {
  const { userEmail, compName, event_name, date } = data;

  try {
    const response = await sendAccessGrantedEmail({
      userEmail,
      compName,
      event_name,
      date,
    });

    return { success: true, response };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

//========================================================to send music upload reminder email
export const sendMusicReminderService = async (data) => {
  const { userEmail, studioName } = data;

  try {
    const response = await sendMusicReminder({
      userEmail,
      studioName,
    });

    return { success: true, response };
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};

//========================================================to send subscription email
export const sendSubscriptionEmailService = async (data) => {
  const { userEmail, studioName, studioEmail, compName } = data;

  try {
    const response = await sendSubscriptionEmail({
      userEmail,
      studioName,
      studioEmail,
      compName,
    });

    return { success: true, response };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

//========================================================to send age division override request email
export const sendAgeOverrideEmailService = async (data) => {
  const {
    userEmail,
    compName,
    studioName,
    studioEmail,
    entryName,
    age_division,
  } = data;
  try {
    const response = await sendAgeOverrideEmail({
      userEmail,
      studioName,
      studioEmail,
      compName,
      entryName,
      age_division,
    });

    return { success: true, response };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

export const addNewGenre = createAsyncThunk(
  "genres/addNewGenre",

  async (newGenre, thunkAPI) => {
    const state = thunkAPI.getState();

    console.log("I AM HERE", state);
    // const userId = state.auth.u; // adjust this line to match where your userId is in the state
    // Add the new genre
    // const docRef = doc(db, "genres", newGenre);
    // await setDoc(docRef, { name: newGenre, userId: userId });

    // Update the organizer document
    // const organizerRef = doc(db, 'organizers', userId);
    // await updateDoc(organizerRef, {
    //   genres: firebase.firestore.FieldValue.arrayUnion(newGenre)
    // });

    return newGenre;
  }
);

//=============================== to get all existing events
export const getEventsService = async (uid) => {
  // const docRef = doc(db, "organizers", uid);
  const docRef = doc(db, "competitions", uid);
  const docSnapshot = await getDoc(docRef);
  return (docSnapshot.exists() && docSnapshot.data().events) || [];
};

//=============================== to add new event
export const addEventService = async (data) => {
  console.log("data", data);
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerData = (await getDoc(organizerDocRef)).data() || {};
    const currentEvents = organizerData?.events || [];
    const updatedEvents = [...currentEvents, data?.data];

    console.log("updatedEvents", updatedEvents);

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

//=============================== to delete event
export const deleteEventService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerData = (await getDoc(organizerDocRef)).data() || {};
    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents.filter(
      (event) => !("event_uid" in event && event.event_uid === data?.event_uid)
    );

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

//=============================== to edit event
export const editEventService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const docSnapshot = await getDoc(organizerDocRef);
    if (docSnapshot.exists()) {
      const currentEvents = docSnapshot.get("events") || [];
      const updatedEvents = currentEvents.map((event) => {
        return "event_uid" in event && event.event_uid === data?.data?.event_uid
          ? { ...event, ...data?.data }
          : event;
      });

      await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

      return { success: true };
    } else {
      throw new Error("Document not found");
    }
  } catch (error) {
    console.log(error);
    return { success: false, message: error?.message };
  }
};

//=============================== to add event to favorites
export const addToFavoritesService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const docSnapshot = await getDoc(organizerDocRef);
    if (docSnapshot.exists()) {
      const events = docSnapshot.data()?.events || [];
      const updatedEvents = events.map((event) =>
        event.event_uid === data?.event_uid
          ? { ...event, favorite: true }
          : event
      );
      await updateDoc(organizerDocRef, { events: updatedEvents });
      return { success: true };
    } else {
      throw new Error("Document not found");
    }
  } catch (error) {
    return { success: false };
  }
};

//=============================== to remove event from favorite
export const removeFromFavoritesService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const docSnapshot = await getDoc(organizerDocRef);
    if (docSnapshot.exists()) {
      const events = docSnapshot.data()?.events || [];
      const updatedEvents = events.map((event) =>
        event.event_uid === data?.event_uid
          ? { ...event, favorite: false }
          : event
      );
      await updateDoc(organizerDocRef, { events: updatedEvents });
      return { success: true };
    } else {
      throw new Error("Document not found");
    }
  } catch (error) {
    return { success: false };
  }
};

//=============================== to add organizer data to firestore

export const addOrganizerData = async (data) => {
  try {
    console.log("Data received in addOrganizerData:", data);

    const {
      danceGenres,
      eligibilityDivisions,
      competitionLevel,
      entryTypes,
      specialEntryTypes,
      scheduling,
      pricing,
    } = data;
    const {
      auth: {
        user: {
          user: { uid },
        },
      },
      ...dataWithoutAuth
    } = data;

    const competitonsData = {
      compId: uid,
      danceGenres,
      eligibilityDivisions,
      competitionLevel,
      entryTypes,
      specialEntryTypes,
      scheduling,
      pricing,
    };

    dataWithoutAuth.formCompleted = true;

    delete dataWithoutAuth._persist;
    delete dataWithoutAuth.organizerCustomClaim;
    delete dataWithoutAuth.auth;

    const organizerDocRef = doc(db, "organizers", uid);
    const competitionsDocRef = doc(db, "competitions", uid);

    await updateDoc(organizerDocRef, dataWithoutAuth);
    await updateDoc(competitionsDocRef, competitonsData);

    console.log(
      "Organizer and competitions data successfully updated in Firestore!"
    );

    return "Registration Successful";
  } catch (error) {
    console.error("Error updating organizer data in Firestore: ", error);

    return "Registration Failed";
  }
};

//=============================== to add studion data to firestore

export const addStudioData = async (data, uid) => {
  try {
    console.log("Data received in addStudioData:", data);

    const organizerDocRef = doc(db, "studio_owners", uid);

    await updateDoc(organizerDocRef, data);

    console.log("Studio data successfully updated in Firestore!");

    return "Registration Successful";
  } catch (error) {
    console.error("Error updating Studio data in Firestore: ", error);

    return "Registration Failed";
  }
};

//=============================== to get all docs in schedule dancers
export const getScheduleCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );
    const scheduleQuerySnapshot = await getDocs(collectionRef);
    return scheduleQuerySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    throw error;
  }
};

//=============================== to add schedule_dancers data to firestore
export const addScheduleCategoryService = async (data) => {
  try {
    const scheduleCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );
    const scheduleQuerySnapshot = await getDocs(scheduleCollectionRef);

    scheduleQuerySnapshot.forEach((doc) => {
      const scheduleData = doc.data();
      if (
        scheduleData.age_division === data?.formData.age_division &&
        scheduleData.classification === data?.formData.classification &&
        scheduleData.entryType === data?.formData.entryType &&
        scheduleData.genre === data?.formData.genre
      ) {
        throw new Error("Category already exists");
      }
    });

    const querySnapshotAll = await getDocs(scheduleCollectionRef);

    await setDoc(
      doc(scheduleCollectionRef, `category_${assignSortId(querySnapshotAll)}`),
      {
        ...data?.formData,
        sort_id: Number(assignSortId(querySnapshotAll)),
        doc_id: `category_${assignSortId(querySnapshotAll)}`,
      }
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to delete dancers
export const deleteScheduleCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const deletedCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "deleted_categories"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    querySnapshotCategories.docs.forEach(async (doc) => {
      const categoryData = doc.data();

      if (
        categoryData.age_division === data?.age_division &&
        categoryData.classification === data?.classification &&
        categoryData.entryType === data?.entryType &&
        categoryData.genre === data?.genre
      ) {
        // Delete the matching document from the original collection
        await deleteDoc(doc.ref);

        // Add the deleted document to the "deleted_categories" subcollection
        await addDoc(deletedCollectionRef, categoryData);
      }
    });

    const { message } = await updateAllDocs(
      collectionRef,
      data?.entryTypes,
      data?.currentEvent
    );
    console.log(message);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//================================to edit categories
export const editScheduleCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );
    const querySnapshotCategory = await getDocs(collectionRef);
    querySnapshotCategory.forEach((doc) => {
      doc.data().age_division === data?.oldData?.age_division &&
        doc.data().classification === data?.oldData?.classification &&
        doc.data().entryType === data?.oldData?.entryType &&
        doc.data().genre === data?.oldData?.genre &&
        updateDoc(doc.ref, data?.formData);
    });
    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to scratch category
export const scratchScheduleCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        const categoryData = doc.data();
        if (
          categoryData.age_division === data?.age_division &&
          categoryData.classification === data?.classification &&
          categoryData.entryType === data?.entryType &&
          categoryData.genre === data?.genre &&
          categoryData.event_id === data?.currentEvent?.event_name
        ) {
          await updateDoc(doc.ref, { is_scratched: true });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to unscratch category
export const unscratchScheduleCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        const categoryData = doc.data();
        if (
          categoryData.age_division === data?.age_division &&
          categoryData.classification === data?.classification &&
          categoryData.entryType === data?.entryType &&
          categoryData.genre === data?.genre &&
          categoryData.event_id === data?.currentEvent?.event_name
        ) {
          await updateDoc(doc.ref, { is_scratched: false });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//================================to add minutes to entries after existing entry
async function addMinutesToEntries(data) {
  const collectionRef = collection(
    db,
    "competitions",
    data?.compId,
    "schedule_dancers"
  );
  const querySnapshotCategories = await getDocs(collectionRef);

  querySnapshotCategories.forEach(async (doc) => {
    // Check if the document matches any object in the existingCategory array
    if (
      data?.categoriesAfterExisting.some(
        (category) => category.sort_id === doc.data()?.sort_id
      )
    ) {
      const entries = doc.data()?.entries || [];

      const updatedEntries = entries.map((entry) => {
        if (entry.time) {
          const newTime = moment(entry.time, "hh:mm A")
            .add(data?.minutesToAddCat, "minutes")
            .format("hh:mm A");
          return { ...entry, time: newTime };
        }
        return entry;
      });

      await updateDoc(doc.ref, {
        entries: updatedEntries,
      });
    }
  });
}

//=========================================to generate uid
function generateUID() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uid += chars.charAt(randomIndex);
  }
  return uid;
}
function generateTimestamp() {
  const now = new Date();
  const isoString = now.toISOString();
  return isoString;
}

//=================================================================to add entry
export const addScheduleEntryService = async (data) => {
  try {
    const scheduleCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    // Query the documents based on multiple field values
    const querySnapshot = await getDocs(
      query(
        scheduleCollectionRef,
        where("age_division", "==", data?.formData.age_division),
        where("classification", "==", data?.formData.classification),
        where("entryType", "==", data?.formData.entryType),
        where("genre", "==", data?.formData.genre),
        where("event_id", "==", data?.formData.event_id)
      )
    );

    // Query all the documents in the collection
    const querySnapshotAll = await getDocs(scheduleCollectionRef);

    let err = "";

    // Check if any matching entry exists in any document
    for (const doc of querySnapshotAll.docs) {
      try {
        const documentData = doc.data();
        const entries = documentData.entries || [];

        const existingEntry = entries.find((item) => {
          return (
            item.entry_name.toLowerCase() ===
              data?.formData.entry_name.toLowerCase() &&
            item.event_id.toLowerCase() ===
              data?.formData.event_id.toLowerCase()
          );
        });

        if (existingEntry) {
          throw new Error("Entry already exists");
        }
      } catch (error) {
        err = error;
        throw new Error(error.message);
      }
    }

    // const querySnapshotAll = await getDocs(collectionRef);

    // Check if any matching document exists
    if (querySnapshot.empty) {
      // Create a new document with the specified ID and fields
      console.log("creating new document to add entry - organizer");

      await setDoc(
        doc(
          scheduleCollectionRef,
          `category_${assignSortId(querySnapshotAll)}`
        ),
        {
          age_division: data?.formData.age_division,
          classification: data?.formData.classification,
          entryType: data?.formData.entryType,
          genre: data?.formData.genre,
          entries: [data.formData],
          id: data?.uniqueId,
          compId: data?.compId,
          event_id: data?.event_id,
          sort_id: Number(assignSortId(querySnapshotAll)),
          category: true,
          is_scratched: false,
          doc_id: `category_${assignSortId(querySnapshotAll)}`,
        }
      );
    } else {
      // Iterate over the query snapshot results
      console.log("adding entry to existing document - organizer");

      querySnapshot.forEach(async (doc) => {
        const documentRef = doc.ref;

        try {
          // Get the current value of the entries field
          const documentData = (await doc.data()) || {};
          const currentEntries = documentData.entries || [];

          // Add the new data to the entries array
          const updatedEntries = [...currentEntries, data.formData];

          // Update the document's entries field with the updated array
          await updateDoc(documentRef, { entries: updatedEntries });

          const { message } = await updateAllDocs(
            scheduleCollectionRef,
            data?.entryTypes,
            data?.currentEvent
          );
          console.log(message);
        } catch (error) {
          err = error;
          throw new Error(error.message);
        }
      });
    }

    //update notifs
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `New entry added. Entry ${data?.formData?.entry_name} has been added by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "entry",
      user_id: data?.compId,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    // increment_entries submitted field
    const compDocRef = doc(db, "competitions", data?.compId.replace(/ /g, ""));
    const compSnapshot = await getDoc(compDocRef);
    const events = compSnapshot.data().events;
    const updatedEvents = events?.map((event) => ({
      ...event,
      entries_submitted: (event.entries_submitted || 0) + 1,
    }));

    await updateDoc(compDocRef, {
      events: updatedEvents,
    });

    // Add code to update "time" field values
    if (data?.categoriesAfterExisting?.length > 0) {
      await addMinutesToEntries(data);
    }

    return err !== ""
      ? { success: false, message: "entry exists" }
      : { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

// const querySnapshotAll = await getDocs(collectionRef);
//to build sort_id for category
const assignSortId = (querySnapshotAll) => {
  const documents = querySnapshotAll.docs; // Convert querySnapshot to an array of documents

  // Extract the last 4 characters from the "doc_id" field and get the maximum value
  const maxSortId = documents.reduce((max, doc) => {
    const docId = doc.data().doc_id;
    const lastFourCharacters = docId.slice(-4);
    const numericValue = parseInt(lastFourCharacters, 10);
    return numericValue > max ? numericValue : max;
  }, 0);

  // Create the newSortId by adding 1 to the maximum value
  const newSortId = maxSortId + 1;

  return newSortId.toString().padStart(4, "0"); // Ensure 4 digits with leading zeros
};

//================================to subtract minutes to entries after existing entry
async function subtractMinutesFromEntries(data) {
  const collectionRef = collection(
    db,
    "competitions",
    data?.compId,
    "schedule_dancers"
  );
  const querySnapshotCategories = await getDocs(collectionRef);

  for (const doc of querySnapshotCategories.docs) {
    if (
      data?.categoriesAfterExisting.some(
        (category) => category.sort_id === doc.data()?.sort_id
      )
    ) {
      const entries = doc.data()?.entries || [];

      const updatedEntries = entries.map((entry) => {
        if (entry.time) {
          const newTime = moment(entry.time, "hh:mm A")
            .subtract(data?.minutesToSubtractCat, "minutes")
            .format("hh:mm A");
          return { ...entry, time: newTime };
        }
        return entry;
      });

      await updateDoc(doc.ref, {
        entries: updatedEntries,
      });
    }
  }
}

//==========================to delete entry
export const deleteScheduleEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        const categoryData = doc.data();
        if (
          categoryData.age_division === data?.age_division &&
          categoryData.classification === data?.classification &&
          categoryData.entryType === data?.entryType &&
          categoryData.genre === data?.genre
        ) {
          const entryIndex =
            categoryData.entries?.findIndex(
              (entry) => entry?.doc_id === data?.doc_id
            ) ?? -1;
          if (entryIndex !== -1) {
            const updatedEntries = [...(categoryData.entries ?? [])];
            updatedEntries.splice(entryIndex, 1);

            await updateDoc(doc.ref, { entries: updatedEntries });
          }
        }
      })
    );

    // Add code to update "time" field values
    if (data?.categoriesAfterExisting?.length > 0) {
      await subtractMinutesFromEntries(data);
    }
    const { message } = await updateAllDocs(
      collectionRef,
      data?.entryTypes,
      data?.currentEvent
    );
    console.log(message);

    //update notifs
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `Entry deleted. Entry ${data?.entry_name} has been deleted by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "entry",
      user_id: data?.compId,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to scratch entry
export const scratchScheduleEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        const categoryData = doc.data();
        if (
          categoryData.age_division === data?.age_division &&
          categoryData.classification === data?.classification &&
          categoryData.entryType === data?.entryType &&
          categoryData.genre === data?.genre &&
          categoryData.event_id === data?.currentEvent?.event_name
        ) {
          const entryIndex =
            categoryData.entries?.findIndex(
              (entry) => entry.doc_id === data?.doc_id
            ) ?? -1;
          if (entryIndex !== -1) {
            const updatedEntries = [...(categoryData.entries ?? [])];
            updatedEntries[entryIndex].is_scratched = true;

            await updateDoc(doc.ref, { entries: updatedEntries });
          }
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to unscratch entry
export const unscratchScheduleEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        const categoryData = doc.data();
        if (
          categoryData.age_division === data?.age_division &&
          categoryData.classification === data?.classification &&
          categoryData.entryType === data?.entryType &&
          categoryData.genre === data?.genre &&
          categoryData.event_id === data?.currentEvent?.event_name
        ) {
          const entryIndex =
            categoryData.entries?.findIndex(
              (entry) => entry.doc_id === data?.doc_id
            ) ?? -1;
          if (entryIndex !== -1) {
            const updatedEntries = [...(categoryData.entries ?? [])];
            updatedEntries[entryIndex].is_scratched = false;

            await updateDoc(doc.ref, { entries: updatedEntries });
          }
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================================new edit entry
export const editEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);
    const category = docSnapshot.data();

    //if its a normal edit
    if (
      category?.age_division === data?.formData?.age_division &&
      category?.classification === data?.formData?.classification &&
      category?.entryType === data?.formData?.entryType &&
      category?.genre === data?.formData?.genre
    ) {
      const entries = category?.entries;

      const updatedEntries = entries.map((entry) => {
        if (entry.entry_count === data?.entry_count) {
          return { ...entry, ...data?.formData };
        }
        return entry;
      });
      await updateDoc(docRef, { entries: updatedEntries });
      console.log("normal edit done successfully");
    }

    //if any of the unique category prop is changed
    if (
      category?.age_division !== data?.formData?.age_division ||
      category?.classification !== data?.formData?.classification ||
      category?.entryType !== data?.formData?.entryType ||
      category?.genre !== data?.formData?.genre
    ) {
      // Check if a document with matching age_division and classification exists
      const querySnapshot = await getDocs(collectionRef);
      let matchingDocumentFound = false;

      //to first delete the entry from the prev doc
      const prevEntries = category?.entries;
      const updatedEntries = prevEntries?.filter(
        (entr) => entr?.entry_count !== data?.entry_count
      );
      updateDoc(docRef, { entries: updatedEntries });

      querySnapshot.forEach((docSnapshot) => {
        const existingCategory = docSnapshot.data();
        if (
          existingCategory?.age_division === data?.formData?.age_division &&
          existingCategory?.classification === data?.formData?.classification &&
          existingCategory?.entryType === data?.formData?.entryType &&
          existingCategory?.genre === data?.formData?.genre
        ) {
          matchingDocumentFound = true;

          const entries = docSnapshot.data()?.entries || [];
          entries.push(data?.formData);
          updateDoc(docSnapshot.ref, { entries });
          console.log("Edit in existing document done successfully");
        }
      });

      if (!matchingDocumentFound) {
        const querySnapshotAll = await getDocs(collectionRef);

        const newDocumentRef = doc(
          collectionRef,
          `category_${assignSortId(querySnapshotAll)}`
        );

        await setDoc(newDocumentRef, {
          age_division: data?.formData.age_division,
          classification: data?.formData.classification,
          entryType: data?.formData.entryType,
          genre: data?.formData.genre,
          event_id: data?.formData.event_id,
          entries: [data?.formData],
          id: generateUniqueId(),
          sort_id: Number(assignSortId(querySnapshotAll)),
          compId: data?.compId,
          category: true,
          is_scratched: false,
          doc_id: `category_${assignSortId(querySnapshotAll)}`,
        });
        console.log("document not found, New doc created");
      }
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

function generateUniqueId() {
  const timestamp = new Date().getTime().toString();
  const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
  const uniqueId = `${timestamp}${randomNum}`;
  return uniqueId.substring(0, 15);
}

//=============================== to add break to firestore
export const addBreakService = async (data) => {
  try {
    const scheduleCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    // Get all documents in the collection
    const querySnapshot = await getDocs(scheduleCollectionRef);

    // Arrange the documents in increasing order of sort_id
    const sortedDocuments = querySnapshot.docs.sort((a, b) => {
      const sortIdA = a.data().sort_id;
      const sortIdB = b.data().sort_id;
      return sortIdA - sortIdB;
    });

    // Check if the last document has a key of "break"
    const lastDocument = sortedDocuments[sortedDocuments.length - 1];
    // const secondToLastDocument = sortedDocuments[sortedDocuments.length - 2];

    // if (lastDocument?.data()?.break && data?.break) {
    //   throw new Error("Break already added");
    // }
    if (lastDocument?.data()?.buffer && data?.buffer) {
      throw new Error("Buffer already added");
    }
    // if (
    //   secondToLastDocument?.data()?.category === false &&
    //   lastDocument?.data()?.category === false
    // ) {
    //   throw new Error("Time buffer already added!");
    // }

    // Add the break data as a new document
    await setDoc(
      doc(scheduleCollectionRef, `category_${assignSortId(querySnapshot)}`),
      {
        ...data,
        doc_id: `category_${assignSortId(querySnapshot)}`,
        sort_id: Number(assignSortId(querySnapshot)),
      }
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to delete break in firestore
export const deleteBreakService = async (data) => {
  try {
    const scheduleCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    await deleteDoc(doc(scheduleCollectionRef, data?.doc_id), data);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to lock schedule
export const lockScheduleService = async (data) => {
  try {
    const collectionRef = collection(db, "competitions");
    const docRef = doc(collectionRef, data?.compId);
    const docSnapshot = await getDoc(docRef);

    const competition = docSnapshot.data();
    const updatedEvents = [...(competition.events ?? [])];

    const eventIndex = updatedEvents.findIndex(
      (item) => item.event_name === data?.event_name
    );

    if (eventIndex !== -1) {
      updatedEvents[eventIndex].locked = true;
      await updateDoc(docRef, { events: updatedEvents });
    } else {
      throw new Error("Event not found in competition");
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to lock schedule
export const unlockScheduleService = async (data) => {
  try {
    const collectionRef = collection(db, "competitions");
    const docRef = doc(collectionRef, data?.compId);
    const docSnapshot = await getDoc(docRef);

    const competition = docSnapshot.data();
    const updatedEvents = [...(competition.events ?? [])];

    const eventIndex = updatedEvents.findIndex(
      (item) => item.event_name === data?.event_name
    );

    if (eventIndex !== -1) {
      updatedEvents[eventIndex].locked = false;
      await updateDoc(docRef, { events: updatedEvents });
    } else {
      throw new Error("Event not found in competition");
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to scratch category
export const editScoreService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "scores"
    );

    const querySnapshot = await getDocs(collectionRef);

    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const docData = doc.data();
        if (
          docData.doc_id === data?.doc_id &&
          docData.event_name === data?.event_name &&
          docData.judge_id === data?.judge_id
        ) {
          await updateDoc(doc.ref, {
            ...data?.rowData[data?.index],
            total: data?.total,
          });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================================to submit overall preset
export const submitOverallPresetService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "overall_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      let updatedPreset;
      existingData?.presets
        ? (updatedPreset = [...existingData?.presets, data])
        : (updatedPreset = [data]);
      await updateDoc(docRef, { presets: updatedPreset });
    } else {
      await setDoc(docRef, { presets: [data] });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================================to submit overall award
export const addOverallAwardService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "overall_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      let updatedAwards;
      existingData?.awards
        ? (updatedAwards = [...existingData?.awards, data])
        : (updatedAwards = [data]);
      await updateDoc(docRef, { awards: updatedAwards });
    } else {
      await setDoc(docRef, { awards: [data] });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//========================================================== to edit overall award
export const editOverallAwardService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "overall_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      if (existingData?.awards) {
        const updatedAwards = existingData?.awards?.map((award) =>
          award?.award_uid === data?.award_uid ? { ...award, ...data } : award
        );
        await updateDoc(docRef, { awards: updatedAwards });
      }
    } else {
      throw new Error("Document not found");
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error?.message };
  }
};

//=======================================================to delete overall award
export const deleteOverallAwardService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );
    const docRef = doc(reportsCollectionRef, "overall_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      if (existingData?.awards) {
        const updatedAwards = existingData.awards.filter(
          (award) => award.award_uid !== data?.award_uid
        );
        await updateDoc(docRef, { awards: updatedAwards });
      }
    } else {
      throw new Error("Document not found");
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================================to submit overall preset
export const submitGrandPresetService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "grand_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      let updatedPreset;
      existingData?.presets
        ? (updatedPreset = [...existingData?.presets, data])
        : (updatedPreset = [data]);
      await updateDoc(docRef, { presets: updatedPreset });
    } else {
      await setDoc(docRef, { presets: [data] });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================================to submit grand award
export const addGrandAwardService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "grand_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      let updatedAwards;
      existingData?.awards
        ? (updatedAwards = [...existingData?.awards, data])
        : (updatedAwards = [data]);
      await updateDoc(docRef, { awards: updatedAwards });
    } else {
      await setDoc(docRef, { awards: [data] });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//========================================================== to edit overall award
export const editGrandAwardService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "grand_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      if (existingData?.awards) {
        const updatedAwards = existingData?.awards?.map((award) =>
          award?.award_uid === data?.award_uid ? { ...award, ...data } : award
        );
        await updateDoc(docRef, { awards: updatedAwards });
      }
    } else {
      throw new Error("Document not found");
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error?.message };
  }
};

//=======================================================to delete overall award
export const deleteGrandAwardService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );
    const docRef = doc(reportsCollectionRef, "grand_preset");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      if (existingData?.awards) {
        const updatedAwards = existingData.awards.filter(
          (award) => award.award_uid !== data?.award_uid
        );
        await updateDoc(docRef, { awards: updatedAwards });
      }
    } else {
      throw new Error("Document not found");
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================== to delete preset
export const deletePresetService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, `${data?.doc}`);

    const existingData = (await getDoc(docRef)).data();
    const presets = existingData?.presets || [];

    const presetIndex = presets.findIndex(
      (preset) => preset.preset_uid === data?.preset_uid
    );

    if (presetIndex !== -1) {
      presets.splice(presetIndex, 1);
      await updateDoc(docRef, { presets });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================== to Edit preset
export const editPresetService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );
    const docRef = doc(reportsCollectionRef, `${data?.doc}`);

    const existingData = (await getDoc(docRef)).data();
    const presets = existingData?.presets || [];

    // Find the index of the preset you want to edit
    const presetIndex = presets.findIndex(
      (preset) => preset.preset_uid === data?.preset_uid
    );

    if (presetIndex !== -1) {
      presets[presetIndex] = {
        ...presets[presetIndex],
        ...data.updatedPresetData,
      };

      await updateDoc(docRef, { presets });

      return { success: true };
    } else {
      return { success: false, message: "Preset not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================================to submit custom request
export const submitCustomRequestService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "custom_request");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      let updatedPreset;
      existingData?.requests
        ? (updatedPreset = [...existingData?.requests, data])
        : (updatedPreset = [data]);
      await updateDoc(docRef, { requests: updatedPreset });
    } else {
      await setDoc(docRef, { requests: [data] });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================== to delete request
export const deleteCustomRequestService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, `${data?.doc}`);

    const existingData = (await getDoc(docRef)).data();
    const requests = existingData?.requests || [];

    const presetIndex = requests.findIndex(
      (preset) => preset.request_uid === data?.request_uid
    );

    if (presetIndex !== -1) {
      requests.splice(presetIndex, 1);
      await updateDoc(docRef, { requests });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================================to submit created label
export const submitAwardLabelService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, "award_labels");

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const existingData = docSnapshot.data();
      let updatedPreset;
      existingData?.labels
        ? (updatedPreset = [...existingData?.labels, data])
        : (updatedPreset = [data]);
      await updateDoc(docRef, { labels: updatedPreset });
    } else {
      await setDoc(docRef, { labels: [data] });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================== to delete request
export const deleteAwardLabelService = async (data) => {
  try {
    const reportsCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "reports"
    );

    const docRef = doc(reportsCollectionRef, `${data?.doc}`);

    const existingData = (await getDoc(docRef)).data();
    const labels = existingData?.labels || [];

    const presetIndex = labels.findIndex(
      (preset) => preset.label_uid === data?.label_uid
    );

    if (presetIndex !== -1) {
      labels.splice(presetIndex, 1);
      await updateDoc(docRef, { labels });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const updateJudgeSettingsService = async (data) => {
  try {
    const organizerDocRef = doc(db, "organizers", `${data?.compId}`);
    const updateData = {
      judge_settings: data?.settingsData,
    };

    await updateDoc(organizerDocRef, updateData);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

// ============================= to update the award name
export const updateAwardNameService = async (data) => {
  try {
    const organizerDocRef = doc(db, "organizers", `${data?.compId}`);
    const competitionsDocRef = doc(db, "competitions", `${data?.compId}`);

    const updateData = {
      awards: data?.updatedArray,
    };

    await updateDoc(organizerDocRef, updateData);
    await updateDoc(competitionsDocRef, updateData);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

// ============================= to update the award name
export const updatePriceDataService = async (data) => {
  try {
    const organizerDocRef = doc(db, "organizers", `${data?.compId}`);
    const competitionsDocRef = doc(db, "competitions", `${data?.compId}`);

    const arr = [...data?.updatePrice];
    const updateData = {
      pricing: { pricingCategories: arr },
    };

    await updateDoc(organizerDocRef, updateData);
    await updateDoc(competitionsDocRef, updateData);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to toggle scores visibility for events
export const toggleScoresVisibilityService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerDataSnapshot = await getDoc(organizerDocRef);
    const organizerData = organizerDataSnapshot.data() || {};

    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents?.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return { ...event, scores_visibility: !event.scores_visibility };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};

//=============================== to toggle scores visibility for events
export const toggleStudioVisibilityService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerDataSnapshot = await getDoc(organizerDocRef);
    const organizerData = organizerDataSnapshot.data() || {};

    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents?.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return { ...event, studio_visibility: !event.studio_visibility };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};

//=============================== to toggle scores visibility for events
export const toggleRankService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerDataSnapshot = await getDoc(organizerDocRef);
    const organizerData = organizerDataSnapshot.data() || {};

    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents?.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return { ...event, rank_visibility: !event.rank_visibility };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};

export const saveGenreVisibilityService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerDataSnapshot = await getDoc(organizerDocRef);
    const organizerData = organizerDataSnapshot.data() || {};

    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents?.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return { ...event, genre_visibility: data?.checkboxData };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};

export const saveLiveUpdateService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerDataSnapshot = await getDoc(organizerDocRef);
    const organizerData = organizerDataSnapshot.data() || {};

    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents?.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return { ...event, live_update: data?.live_update };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};

//=============================== to enable access for events
export const enableAccessService = async (data) => {
  try {
    const compCollectionRef = collection(db, "competitions");
    const docRef = doc(compCollectionRef, data?.compId);

    const docSnapshot = await getDoc(docRef);

    const compData = docSnapshot.data();

    if (!compData) {
      return { success: false, message: "No competition found." };
    }

    const updatedEvents = compData.events.map((event) => {
      if (event.event_uid === data?.event_uid) {
        const existingAccess = event.access || [];

        // Check if the data already exists in the access array
        const accessAlreadyExists = existingAccess.some(
          (accessItem) => accessItem.studio_id === data?.access.studio_id
        );

        if (!accessAlreadyExists) {
          const updatedAccess = [...existingAccess, data?.access];
          return { ...event, access: updatedAccess };
        }
      }
      return event;
    });

    console.log("Updated Events:", updatedEvents);

    await updateDoc(docRef, {
      events: updatedEvents,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to disable access for events
export const disableAccessService = async (data) => {
  try {
    const compCollectionRef = collection(db, "competitions");
    const docRef = doc(compCollectionRef, data?.compId);

    const docSnapshot = await getDoc(docRef);

    const compData = docSnapshot.data();

    if (!compData) {
      return { success: false, message: "No competition found." };
    }

    const updatedEvents = compData.events.map((event) => {
      if (event.event_uid === data?.event_uid) {
        const updatedAccess = event.access.filter(
          (studio) => studio.studio_id !== data?.access.studio_id
        );
        return { ...event, access: updatedAccess };
      }
      return event;
    });

    await updateDoc(docRef, {
      events: updatedEvents,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//===================================================================to set tax rate
export const setTaxRateService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const eventsSnapshot = await getDoc(organizerDocRef);
    const eventsData = eventsSnapshot.data();

    const updatedEvents = eventsData.events.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return {
          ...event,
          tax_rate: data?.tax_rate,
        };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};

//=============================================================to handle manual update
export const updateManualScoresService = async (data) => {
  try {
    const compId = data?.compId;

    // Get references to the collections
    const scoresCollectionRef = collection(
      db,
      "competitions",
      compId,
      "scores"
    );
    const manualScoresCollectionRef = collection(
      db,
      "competitions",
      compId,
      "manual_scores"
    );

    // Query all documents in the "scores" collection
    const scoresQuerySnapshot = await getDocs(scoresCollectionRef);

    // Query all documents in the "manual_scores" collection
    const manualScoresQuerySnapshot = await getDocs(manualScoresCollectionRef);

    // Create an array of existing document IDs in the "manual_scores" collection
    const existingManualScoresIds = manualScoresQuerySnapshot.docs.map(
      (doc) => doc.id
    );

    // Iterate through the "scores" collection documents
    for (const scoreDoc of scoresQuerySnapshot.docs) {
      const scoreData = scoreDoc.data();
      const scoreDocId = scoreDoc.id;

      // Check if the document exists in the "manual_scores" collection
      if (
        !existingManualScoresIds.includes(scoreDocId) &&
        scoreData?.event_name === data?.event_name
      ) {
        await setDoc(doc(manualScoresCollectionRef, scoreDocId), scoreData);
      }
    }

    //update the timestamp
    await updateTimestamp(data);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const updateTimestamp = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.uid);

    const organizerDataSnapshot = await getDoc(organizerDocRef);
    const organizerData = organizerDataSnapshot.data() || {};

    const currentEvents = organizerData?.events || [];
    const updatedEvents = currentEvents?.map((event) => {
      if (event.event_uid === data?.event_uid) {
        return { ...event, update_timestamp: data?.update_timestamp };
      }
      return event;
    });

    await setDoc(organizerDocRef, { events: updatedEvents }, { merge: true });

    return { success: true };
  } catch (error) {
    console.log(error);
    return { success: false, message: error.message };
  }
};
