// import { useState } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  closeDropboard,
  // toggleAddDancer,
} from 'redux/danceStudio/danceStudioSlice';

const SideBarCompetition = ({ eventSelect }) => {
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  // const { compId, competitionName } = danceStudioCompData.value;

  // const { addDancer } = useSelector((state) => state.danceStudio);
  const location = useLocation();
  const currentPage = location.pathname;
  const navigate = useNavigate();
  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem('event_name') || '';
  const dispatch = useDispatch();

  // const [dancersDropdown, setDancersDropdown] = useState(false);
  // function toggledancersDropdown() {
  //   setDancersDropdown((prev) => !prev);
  // }

  return (
    <>
      {' '}
      {(currentPage.includes('event/') || danceStudioCompData?.value?.compId) &&
        event_name &&
        !currentPage.includes('competition-selection') && (
          <nav className="mt-6 text-[.85rem]">
            <div>
              <div className="">
                <p className="text-white/40">Competition</p>
              </div>
            </div>

            <div className="mt-3">
              <div
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(/ /g, '-')}/entries`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage.includes('/entries') && 'bg-[#282929]'
                }`}
              >
                {currentPage.includes('/entries') && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}
                <div className="flex gap-2 items-center">
                  <img
                    alt="card"
                    src="/images/IdentificationCard.png"
                    className="w-5 h-5"
                  />

                  <p>Entries</p>
                </div>
              </div>

              <div
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(/ /g, '-')}/schedule`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage.includes('/schedule') && 'bg-[#282929]'
                }`}
              >
                {currentPage.includes('/schedule') && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}
                <div className="flex gap-2 items-center">
                  <img
                    alt="building"
                    src="/images/BookOpen.png"
                    className="w-5 h-5"
                  />

                  <p>Schedule</p>
                </div>
              </div>
              <div
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(/ /g, '-')}/scores`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] py-1 pl-8 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage.includes('/scores') && 'bg-[#282929]'
                }`}
              >
                {currentPage.includes('/scores') && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}

                <div className="flex gap-2 items-center">
                  <img
                    alt="building"
                    src="/images/notebook.png"
                    className="w-5 h-5"
                  />

                  <p>Scores</p>
                </div>
              </div>
              <div
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(/ /g, '-')}/musics`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage.includes('/musics') && 'bg-[#282929]'
                }`}
              >
                {currentPage.includes('/musics') && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}
                <div className="flex gap-2 items-center">
                  <img
                    alt="building"
                    src="/images/MusicNote.png"
                    className="w-5 h-5"
                  />

                  <p>Music</p>
                </div>
              </div>
            </div>
          </nav>
        )}
      {/* dancers */}
      {(currentPage.includes('event/') || danceStudioCompData?.value?.compId) &&
        event_name &&
        !currentPage.includes('competition-selection') && (
          <nav className="mt-4 text-[.85rem]">
            <div>
              <div className="">
                <p className="text-white/40">Studio</p>
              </div>
            </div>
            <div className="mt-3">
              <div
                // onClick={toggledancersDropdown}
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(/ /g, '-')}/dancers`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative `}
              >
                <div className="flex gap-2 items-center">
                  <img
                    alt="card"
                    src="/images/IdentificationBadge.png"
                    className="w-5 h-5"
                  />

                  <p>Dancers</p>
                </div>
              </div>
            </div>
          </nav>
        )}
      {/* accounting */}
      {(currentPage.includes('event/') || danceStudioCompData?.value?.compId) &&
        event_name && (
          <nav className="mt-6 text-[.85rem]">
            <div>
              <div className="">
                <p className="text-white/40">Accounting</p>
              </div>
            </div>
            <div className="mt-3">
              <div
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(
                      / /g,
                      '-'
                    )}/accounting-overview`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage.includes('/accounting-overview') && 'bg-[#282929]'
                }`}
              >
                {currentPage.includes('/accounting-overview') && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}
                <div className="flex gap-2 items-center">
                  <img
                    alt="card"
                    src="/images/notebook.png"
                    className="w-5 h-5"
                  />

                  <p>Overview</p>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div
                onClick={() => {
                  dispatch(closeDropboard());
                  navigate(
                    `/studio/event/${event_name?.replace(
                      / /g,
                      '-'
                    )}/dancers-fees`
                  );
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage.includes('/dancers-fees') && 'bg-[#282929]'
                }`}
              >
                {currentPage.includes('/dancers-fees') && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}
                <div className="flex gap-2 items-center">
                  <img
                    alt="card"
                    src="/images/notebook.png"
                    className="w-5 h-5"
                  />

                  <p>Dancers Fees</p>
                </div>
              </div>
            </div>
          </nav>
        )}
    </>
  );
};

export default SideBarCompetition;
